import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/branches';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      type: Yup.string().required('Este campo é obrigatório'),
      cpf_cnpj: Yup.string().required('Este campo é obrigatório'),
      ecommerce_id: Yup.string().required('Este campo é obrigatório'),
      erp_id: Yup.string().required('Este campo é obrigatório'),
      first_name: Yup.string().required('Este campo é obrigatório'),
      email: Yup.string().required('Este campo é obrigatório'),
      ddd1: Yup.string().required('Este campo é obrigatório'),
      telephone1: Yup.string().required('Este campo é obrigatório'),
      zip_code: Yup.string().required('Este campo é obrigatório'),
      state_id: Yup.string().required('Este campo é obrigatório'),
      city_id: Yup.string().required('Este campo é obrigatório'),
      neighborhood: Yup.string().required('Este campo é obrigatório'),
      address: Yup.string().required('Este campo é obrigatório'),
      status_code: Yup.string().required('Este campo é obrigatório'),
    });

    const parsedData = parseDataToApi(data);

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
