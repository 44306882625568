import { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEdit2, FiTrash2 } from 'react-icons/fi';

import { Container } from './styles';

import { useParameter } from '~/providers/parameter';
import { useAuth } from '~/providers/auth';

import TableList from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código Efizi' },
  { id: 'name', label: 'Nome' },
  { id: 'description', label: 'Descrição' },
];

function Parameters() {
  const history = useHistory();

  const {
    parameters,
    parametersListLoading,
    index,
    show,
    destroy,
  } = useParameter();
  const { user } = useAuth();

  const [loaded, setLoaded] = useState(false);

  const loadParameters = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () =>
      user.login === 'admin'
        ? {
            label: 'Adicionar Parâmetro',
            handleFunction: () =>
              history.push('/parameters/form', {
                newParameter: true,
              }),
          }
        : null,
    [user, history]
  );

  const actions = useMemo(() => {
    const auxActions = [];

    auxActions.push({
      label: 'Visualizar',
      icon: <FiEye size={20} />,
      handleFunction: async parameter => {
        history.push('/parameters/form', {
          showParameter: true,
        });

        await show({ parameterUuid: parameter.uuid });
      },
    });

    auxActions.push({
      label: 'Editar',
      icon: <FiEdit2 size={20} />,
      handleFunction: async parameter => {
        history.push('parameters/form', {
          updateParameter: true,
        });

        await show({ parameterUuid: parameter.uuid });
      },
    });

    if (user.login === 'admin')
      auxActions.push({
        label: 'Remover',
        icon: <FiTrash2 size={20} />,
        handleFunction: parameter => {
          destroy({ parameter });
        },
      });

    return auxActions;
  }, [history, show, destroy, user]);

  useEffect(() => {
    if (!loaded && parameters.length <= 1) loadParameters();

    setLoaded(true);
  }, [parameters, loadParameters, loaded]);

  return (
    <Container>
      <TableList
        newItem={newItem}
        headCells={headCells}
        data={parameters}
        loadData={loadParameters}
        loading={parametersListLoading}
        actions={actions}
      />
    </Container>
  );
}

export default Parameters;
