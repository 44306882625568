import styled from 'styled-components';

import Dlg from '@material-ui/core/Dialog';

export { default as DialogTitle } from '@material-ui/core/DialogTitle';
export { default as List } from '@material-ui/core/List';
export { default as ListItem } from '@material-ui/core/ListItem';
export { default as Button } from '@material-ui/core/Button';

export const Dialog = styled(Dlg).attrs({})`
  &.MuiDialog-root {
    .MuiPaper-root {
      padding: 2em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;
