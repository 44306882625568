import { cnpjMask, cpfMask, numberUnmask } from './mask';

export function parseToView(users = []) {
  return users.map(user => ({
    ...user,
    cpf_cnpj:
      user.type === 'F' ? cpfMask(user.cpf_cnpj) : cnpjMask(user.cpf_cnpj),
  }));
}

export function parseDataToApi(user = {}) {
  return {
    ...user,
    cpf_cnpj: numberUnmask(user.cpf_cnpj),
  };
}

export function parseFormData(user = {}) {
  return {
    cpf_cnpj: user.cpf_cnpj,
    name: user.name,
    email: user.email,
    login: user.login,
  };
}
