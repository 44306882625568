import styled from 'styled-components';

import IconButton from '@material-ui/core/IconButton';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Tabs from '@material-ui/core/Tabs';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Drawer from '@material-ui/core/Drawer';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Logo = styled.img``;

export const HeaderContent = styled(AppBar).attrs({
  position: 'fixed',
})`
  &.MuiPaper-root {
    background: ${props => props.theme.colors.backFirst};
    flex-direction: row;
  }
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const LogoContent = styled(Toolbar)``;

export const MenuButton = styled(IconButton).attrs({
  edge: 'start',
  color: 'primary',
})``;

export const DrawerContent = styled(Drawer)`
  > .MuiPaper-root {
    background: ${props => props.theme.colors.backFirst};
  }
`;

export const ListItemIconContent = styled(ListItemIcon)`
  > svg {
    color: ${props => props.theme.colors.secondary};
    margin-left: 6px;
  }
`;
export const ListItemTitle = styled(ListItemText)`
  color: ${props => props.theme.colors.primary};
`;

export const Image = styled.img`
  width: 40px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const Main = styled.main`
  padding: 0 !important;
`;

export const RouteContent = styled.div`
  align-items: center;
  margin: 60px 0 0 0;
  height: 40px;
  width: 100%;
  background: ${props => props.theme.colors.secondary};
`;

export const RouteTitle = styled.h1`
  text-align: center;
  padding: 4px 0;
  font-size: 24px;
  color: ${props => props.theme.colors.backFirst};
  font-weight: 400;
`;

export const TabsContent = styled(Paper).attrs({})`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 48px;
  border-radius: 0 !important;
  background: ${props => props.theme.colors.primary} !important;
  > .MuiTabs-root {
    display: ${props => (props.locate ? 'none' : 'flex')};
  }
`;

export const HeaderTabs = styled(Tabs)`
  display: flex;
  width: 100%;
`;

export const TabText = styled(Tab)`
  color: ${props => props.theme.colors.backFirst} !important;
`;

export const Content = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
