export function parseToView(orders = []) {
  return orders.map(order => ({
    ...order,
  }));
}

export function parseDataToApi(order = {}) {
  return {
    ...order,
  };
}

export function parseFormData(order = {}) {
  return {
    start_at: order.start_at,
    end_at: order.end_at,
  };
}
