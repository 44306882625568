import { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  product_integrations: [],
  product_integration: {},
  product_integrationErrors: {},
  product_integrationLoading: false,
  product_integrationListLoading: false,
};

const ProductIntegrationContext = createContext(INITIAL_STATE);

export function ProductIntegrationProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setIntegrationData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setIntegrationData({ product_integrationListLoading: true });

      const integrationData = await actions.index({
        search,
        order_by,
        order,
      });

      if (integrationData.product_integrationErrors)
        setErrorHandlerData({
          error: {
            ...integrationData.product_integrationErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setIntegrationData({
        ...integrationData,
        product_integrationListLoading: false,
      });
    },
    [setIntegrationData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ product_integrationUuid = '' }) => {
      setIntegrationData({ product_integrationLoading: true });

      const integrationData = await actions.show({ product_integrationUuid });

      if (integrationData.product_integrationErrors) {
        setErrorHandlerData({
          error: {
            ...integrationData.product_integrationErrors,
            resolveFunction: () => show({ product_integrationUuid }),
          },
        });

        history.goBack();
      }

      setIntegrationData({
        ...integrationData,
        product_integrationLoading: false,
      });
    },
    [setIntegrationData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ product_integration = {} }) => {
      setIntegrationData({ product_integrationLoading: true });

      const integrationData = await actions.store({ product_integration });

      if (integrationData.product_integrationErrors) {
        setErrorHandlerData({
          error: {
            ...integrationData.product_integrationErrors,
            resolveFunction: () => store({ product_integration }),
          },
        });

        setIntegrationData({
          ...integrationData,
          product_integrationLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          product_integrations: [
            integrationData,
            ...oldData.product_integrations,
          ],
          product_integrationLoading: false,
        }));

        history.goBack();

        toast.success('Integração cadastrada com sucesso!');
      }
    },
    [setIntegrationData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ product_integration = {} }) => {
      setIntegrationData({ product_integrationLoading: true });

      const integrationData = await actions.update({ product_integration });

      if (integrationData.product_integrationErrors) {
        setErrorHandlerData({
          error: {
            ...integrationData.product_integrationErrors,
            resolveFunction: () => update({ product_integration }),
          },
        });

        setIntegrationData({
          ...integrationData,
          product_integrationLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          product_integrations: [
            integrationData,
            ...oldData.product_integrations.filter(
              auxIntegrations =>
                auxIntegrations.uuid !== product_integration.uuid
            ),
          ],
          product_integrationLoading: false,
        }));

        history.goBack();

        toast.success('Integração atualizada com sucesso!');
      }
    },
    [setIntegrationData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ product_integration = {} }) => {
      setIntegrationData({ product_integrationLoading: true });

      const integrationData = await actions.destroy({ product_integration });

      if (integrationData.product_integrationErrors) {
        setErrorHandlerData({
          error: {
            ...integrationData.product_integrationErrors,
            resolveFunction: () => destroy({ product_integration }),
          },
        });

        setIntegrationData({
          ...integrationData,
          product_integrationLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          product_integrations: [
            ...oldData.product_integrations.filter(
              auxIntegrations =>
                auxIntegrations.uuid !== product_integration.uuid
            ),
          ],
          product_integrationLoading: false,
        }));

        toast.success('Integração removida com sucesso!');
      }
    },
    [setIntegrationData, setErrorHandlerData]
  );

  const newProductIntegration = useCallback(async () => {
    // eslint-disable-next-line no-unused-expressions
    ({ product_integrationLoading: true });

    const integrationData = await actions.newProductIntegration();

    if (integrationData.product_integrationErrors)
      setErrorHandlerData({
        error: {
          ...integrationData.product_integrationErrors,
          resolveFunction: () => newProductIntegration(),
        },
      });

    setIntegrationData({ product_integrationLoading: false });

    return { product_integrations: [], ...integrationData };
  }, [setIntegrationData, setErrorHandlerData]);

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        product_integration: {},
        product_integrationErrors: {},
        product_integrationLoading: false,
        product_integrationListLoading: false,
      };
    });
  }, []);

  return (
    <ProductIntegrationContext.Provider
      value={{
        ...data,
        setIntegrationData,
        index,
        show,
        store,
        update,
        destroy,
        newProductIntegration,
        clearState,
      }}
    >
      {children}
    </ProductIntegrationContext.Provider>
  );
}

export function useIntegrations() {
  const context = useContext(ProductIntegrationContext);

  if (!context) throw new Error('useUser must be used within an UserProvider');

  return context;
}

ProductIntegrationProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
