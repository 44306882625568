export function getData() {
  return {
    branches: JSON.parse(localStorage.getItem('@bling/auth:branches')),
    signed: JSON.parse(localStorage.getItem('@bling/auth:signed')),
    user: JSON.parse(localStorage.getItem('@bling/auth:user')),
    token: JSON.parse(localStorage.getItem('@bling/auth:token')),
    logged_branch: JSON.parse(localStorage.getItem('@bling/auth:logged_branch')), // prettier-ignore
    sectorSelected: JSON.parse(localStorage.getItem('@bling/auth:sectorSelected')), // prettier-ignore
  };
}

export function setData(newData) {
  if (newData.branches) localStorage.setItem('@bling/auth:branches', JSON.stringify(newData.branches)); // prettier-ignore
  if (newData.signed || newData.signed === false) localStorage.setItem('@bling/auth:signed', JSON.stringify(newData.signed)); // prettier-ignore
  if (newData.user) localStorage.setItem('@bling/auth:user', JSON.stringify(newData.user)); // prettier-ignore
  if (newData.token) localStorage.setItem('@bling/auth:token', JSON.stringify(newData.token)); // prettier-ignore
  if (newData.logged_branch) localStorage.setItem('@bling/auth:logged_branch', JSON.stringify(newData.logged_branch)); // prettier-ignore
  if (newData.sectorSelected) localStorage.setItem('@bling/auth:sectorSelected', JSON.stringify(newData.sectorSelected)); // prettier-ignore
}
