import { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  branches: [],
  branch: {},
  branchesErrors: {},
  branchesLoading: false,
  branchesListLoading: false,
};

const BranchesContext = createContext(INITIAL_STATE);

export function BranchesProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setBranchData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setBranchData({ branchesListLoading: true });

      const branchData = await actions.index({
        search,
        order_by,
        order,
      });
      if (branchData.branchesErrors)
        setErrorHandlerData({
          error: {
            ...branchData.branchesErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setBranchData({
        ...branchData,
        branchesListLoading: false,
      });
    },
    [setBranchData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ branchUuid = '' }) => {
      setBranchData({ branchesLoading: true });

      const branchData = await actions.show({ branchUuid });

      if (branchData.branchesErrors) {
        setErrorHandlerData({
          error: {
            ...branchData.branchesErrors,
            resolveFunction: () => show({ branchUuid }),
          },
        });

        history.goBack();
      }

      setBranchData({
        ...branchData,
        branchesLoading: false,
      });
    },
    [setBranchData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ branch = {} }) => {
      setBranchData({ branchesLoading: true });

      const branchData = await actions.store({ branch });
      if (branchData.branchesErrors) {
        setErrorHandlerData({
          error: {
            ...branchData.branchesErrors,
            resolveFunction: () => store({ branch }),
          },
        });

        setBranchData({
          ...branchData,
          branchesLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          branches: [branchData, ...oldData.branches],
          branchesLoading: false,
        }));

        history.goBack();

        toast.success('Filial cadastrada com sucesso!');
      }
    },
    [setBranchData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ branch = {} }) => {
      setBranchData({ branchesLoading: true });

      const branchData = await actions.update({ branch });

      if (branchData.branchesErrors) {
        setErrorHandlerData({
          error: {
            ...branchData.branchesErrors,
            resolveFunction: () => update({ branch }),
          },
        });

        setBranchData({
          ...branchData,
          branchesLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          branches: [
            branchData,
            ...oldData.branches.filter(
              auxBranches => auxBranches.uuid !== branch.uuid
            ),
          ],
          branchesLoading: false,
        }));

        history.goBack();

        toast.success('Filial atualizada com sucesso!');
      }
    },
    [setBranchData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ branch = {} }) => {
      setBranchData({ branchesLoading: true });

      const branchData = await actions.destroy({ branch });

      if (branchData.branchesErrors) {
        setErrorHandlerData({
          error: {
            ...branchData.branchesErrors,
            resolveFunction: () => destroy({ branch }),
          },
        });

        setBranchData({
          ...branchData,
          branchesLoading: false,
        });
      } else {
        setData(oldData => ({
          ...oldData,
          branches: [
            ...oldData.branches.filter(
              auxBranches => auxBranches.uuid !== branch.uuid
            ),
          ],
          branchesLoading: false,
        }));

        toast.success('Filial removida com sucesso!');
      }
    },
    [setBranchData, setErrorHandlerData]
  );

  const newBranch = useCallback(async () => {
    // eslint-disable-next-line no-unused-expressions
    ({ branchesLoading: true });

    const branchData = await actions.newBranch();

    if (branchData.branchesErrors)
      setErrorHandlerData({
        error: {
          ...branchData.branchesErrors,
          resolveFunction: () => newBranch(),
        },
      });

    setBranchData({ branchesLoading: false });

    return { branches: [], ...branchData };
  }, [setBranchData, setErrorHandlerData]);

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        branch: {},
        branchesErrors: {},
        branchesLoading: false,
        branchesListLoading: false,
      };
    });
  }, []);

  return (
    <BranchesContext.Provider
      value={{
        ...data,
        setBranchData,
        index,
        show,
        store,
        update,
        destroy,
        newBranch,
        clearState,
      }}
    >
      {children}
    </BranchesContext.Provider>
  );
}

export function useBranches() {
  const context = useContext(BranchesContext);

  if (!context) throw new Error('useUser must be used within an UserProvider');

  return context;
}

BranchesProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
