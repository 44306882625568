export default {
  title: 'dark',

  colors: {
    primary: '#128c7e',
    secondary: '#25d366',

    backFirst: '#aaa',

    textFirst: '#fff',
  },
};
