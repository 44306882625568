import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;

  .MuiTableCell-root {
    padding: 0.9em;
    div {
      display: -webkit-box;
      -webkit-line-clamp: 0;
      -webkit-box-orient: vertical;
      overflow: hidden;

      text-overflow: ellipsis;
    }
  }
`;
