import React, { useEffect, useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@unform/core';
import Radio from '@material-ui/core/Radio';
import RdGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';

function RadioGroup({ name, label, options, onChange, required, ...rest }) {
  const { fieldName, registerField, error, clearError } = useField(name);

  const inputRef = useRef({ value: '' });
  const [value, setValue] = useState('');

  const handleChange = useCallback(
    event => {
      if (inputRef.current) {
        setValue(event.target.value);

        inputRef.current.value = event.target.value;

        if (onChange) onChange(inputRef.current.value);
      }

      if (error) clearError();
    },
    [error, clearError, onChange]
  );

  useEffect(() => {
    inputRef.current.handleChange = handleChange;

    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, handleChange]);

  return (
    <FormControl component="fieldset" error={!!error}>
      <FormLabel component="legend" style={{ fontSize: 12 }}>
        {label} {required && <b style={{ color: '#d50000' }}>*</b>}
      </FormLabel>
      <RdGroup
        style={{ flexDirection: 'row', marginTop: -4 }}
        value={value}
        onChange={handleChange}
      >
        {options.map(option => (
          <FormControlLabel
            key={option.value}
            style={{ marginTop: -4, marginBottom: -6 }}
            value={option.value}
            control={<Radio color="primary" />}
            label={option.label}
            {...rest}
          />
        ))}
      </RdGroup>
      <FormHelperText>{error || ' '}</FormHelperText>
    </FormControl>
  );
}

export default RadioGroup;

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

RadioGroup.defaultProps = {
  onChange: null,
  required: false,
};
