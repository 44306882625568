import styled from 'styled-components';
import Btn from '@material-ui/core/Button';
import FormGroup from '@material-ui/core/FormGroup';

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  place-items: center;
  margin: 0 auto;
  padding: 1em 2em;
`;

export const DataContent = styled.div`
  display: flex;
  width: 90%;
  gap: 0 20px;
  align-items: center;
  justify-content: start;
  flex-flow: row wrap;
`;

export const SwitchContent = styled(FormGroup)`
  margin-bottom: 1em;
`;

export const Button = styled(Btn).attrs({
  variant: 'contained',
  color: 'primary',
})`
  &.MuiButtonBase-root {
    padding: 0.6em;
    color: ${props => props.theme.colors.backFirst};
    font-size: 1rem;
    width: 200px;
    min-height: 50px;
  }
  align-items: center;
  justify-content: center;
`;
