import { useState, useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEdit2, FiTrash2 } from 'react-icons/fi';

import { Container } from './styles';

import { useBranches } from '~/providers/branches';

import TableList from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'ecommerce_id', label: 'Código Bling' },
  { id: 'erp_id', label: 'Código SAP' },
  { id: 'first_name', label: 'Razão Social' },
  { id: 'last_name', label: 'Nome Fantasia' },
];

function Branch() {
  const history = useHistory();
  const { branches, index, show, branchesListLoading, destroy } = useBranches();

  const [loaded, setLoaded] = useState(false);

  const loadBranches = useCallback(
    (search = '', order_by = 'id', order = 'asc') => {
      index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () => ({
      label: 'Integrar Filial ',
      handleFunction: () =>
        history.push('/branches/form', {
          newBranch: true,
        }),
    }),
    [history]
  );

  const actions = useMemo(() => {
    const auxActions = [
      {
        label: 'Visualizar',
        icon: <FiEye size={20} />,
        handleFunction: branch => {
          history.push('/branches/form', { showBranch: true });
          show({ branchUuid: branch.uuid });
        },
      },
    ];

    auxActions.push({
      label: 'Editar',
      icon: <FiEdit2 size={20} />,
      handleFunction: branch => {
        history.push('/branches/form', { updateBranch: true });
        show({ branchUuid: branch.uuid });
      },
    });

    auxActions.push({
      label: 'Remover',
      icon: <FiTrash2 size={20} />,
      handleFunction: branch => {
        destroy({ branch });
      },
    });

    return auxActions;
  }, [history, show, destroy]);

  useEffect(() => {
    if (!loaded && branches.length <= 1) loadBranches();

    setLoaded(true);
  }, [branches, loadBranches, loaded]);

  return (
    <Container>
      <TableList
        newItem={newItem}
        headCells={headCells}
        data={branches}
        loadData={loadBranches}
        loading={branchesListLoading}
        actions={actions}
      />
    </Container>
  );
}

export default Branch;
