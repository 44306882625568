import React, { useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import CircularProgress from '@material-ui/core/CircularProgress';

// import ImageContainer from '../ImageContainer';

import TableListToolbar from './Toolbar';
import TableListHead from './Header';
import TableListBody from './Body';

import { debounceEvent } from '~/utils/debounce';

import { Container, LoadingWrapper } from './styles';

const useTableStyles = makeStyles(() => ({
  paper: {
    width: '100%',
  },
  tableContainer: {
    height: window.innerHeight - 134,
  },
  table: {
    minWidth: 100,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function TableList({
  newItem,
  headCells,
  otherCells,
  observationCells,
  data,
  loadData,
  loading,
  actions,
  select,
  order,
  setOrder,
  canEdit,
}) {
  const classes = useTableStyles();
  const [orderBy, setOrderBy] = useState('id');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    function updateSize() {
      setHeight(window.innerHeight - 280);
    }

    updateSize();

    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const searchRef = useRef({ value: '' });

  const rows = useMemo(
    () =>
      data.map(item => {
        const itemParsed = { ...item, others: {} };

        if (otherCells)
          otherCells.forEach(otherCell => {
            itemParsed.others[otherCell.id] = item[otherCell.id];
          });
        if (observationCells)
          observationCells.forEach(observationCell => {
            itemParsed.others[observationCell.id] = item[observationCell.id];
          });
        else delete itemParsed.others;

        return itemParsed;
      }),
    [otherCells, observationCells, data]
  );

  const handleChangeSearchText = text => {
    if (searchRef.current) searchRef.current.value = text;
    debounceEvent(() => loadData(text.trim(), orderBy, order), 500)();
  };

  const handleRequestSort = (event, property) => {
    const orderAux = orderBy === property && order === 'asc' ? 'desc' : 'asc';

    setOrder(orderAux);
    setOrderBy(property);

    loadData(searchRef.current && searchRef.current.value, property, orderAux);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Container>
      {loading && (
        <LoadingWrapper>
          <CircularProgress />
        </LoadingWrapper>
      )}
      <Paper className={classes.paper}>
        <TableListToolbar
          newItem={newItem}
          searchRef={searchRef}
          handleChangeSearchText={handleChangeSearchText}
          canEdit={canEdit}
        />
        <TableContainer style={{ height }}>
          <Table stickyHeader size="small" className={classes.table}>
            <TableListHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              headCells={headCells}
              actions={actions}
              select={select}
            />
            <TableListBody
              rows={rows}
              page={page}
              rowsPerPage={rowsPerPage}
              headCells={headCells}
              otherCells={otherCells}
              observationCells={observationCells}
              actions={actions}
              select={select}
            />
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 20]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
    </Container>
  );
}

export default TableList;

const newItemPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  handleFunction: PropTypes.func.isRequired,
});

const actionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    disabled: PropTypes.bool,
    handleFunction: PropTypes.func.isRequired,
  })
);

const selectPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  handleFunction: PropTypes.func.isRequired,
});

const cellsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    image: PropTypes.bool,
  })
);

TableListHead.propTypes = {
  classes: PropTypes.shape().isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  headCells: cellsPropType,
  actions: actionsPropType,
  select: selectPropType,
};

TableListHead.defaultProps = {
  headCells: null,
  actions: null,
  select: null,
};

TableList.propTypes = {
  newItem: newItemPropType,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  observationCells: cellsPropType,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  loadData: PropTypes.func,
  loading: PropTypes.bool,
  actions: actionsPropType,
  select: selectPropType,
  canEdit: PropTypes.bool,
};

TableList.defaultProps = {
  newItem: null,
  otherCells: null,
  observationCells: null,
  data: [],
  loadData: () => {},
  loading: false,
  select: null,
  actions: null,
  canEdit: true,
};
