import React from 'react';
import PropTypes from 'prop-types';

import { ErrorHandlerProvider } from './errorHandler';
import { AuthProvider } from './auth';
import { ThemeProvider } from './theme';
import { UserProvider } from './user';
import { ProductIntegrationProvider } from './productIntegration';
import { OrderProvider } from './orders';
import { BranchesProvider } from './branches';
import { ParameterProvider } from './parameter';

function AppProvider({ children }) {
  return (
    <ErrorHandlerProvider>
      <AuthProvider>
        <BranchesProvider>
          <UserProvider>
            <ProductIntegrationProvider>
              <OrderProvider>
                <ParameterProvider>
                  <ThemeProvider>{children}</ThemeProvider>
                </ParameterProvider>
              </OrderProvider>
            </ProductIntegrationProvider>
          </UserProvider>
        </BranchesProvider>
      </AuthProvider>
    </ErrorHandlerProvider>
  );
}

export default AppProvider;

AppProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
