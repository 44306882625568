import apiBling from '~/config/apiBling';

import { parseToView } from '~/utils/order';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const parsedOrderBy = order_by.replace('_view', '');

    const { data } = await apiBling
      .get('/orders', { params: { search, order_by: parsedOrderBy, order } })
      .then(res => res.data);

    return { orders: parseToView(data) };
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao listar Pedidos',
      local: 'OrderActionsIndex',
    });

    return { orderErrors };
  }
}

export async function show({ ordersUuid = '' }) {
  try {
    const { data } = await apiBling
      .get(`/orders/${ordersUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.order;

    return {
      order: parseToView([{ ...data.order, ...auxData }])[0],
    };
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao visualizar Pedido',
      local: 'OrderActionsShow',
    });

    return { orderErrors };
  }
}

export async function destroy({ order = {} }) {
  try {
    const { data } = await apiBling({
      url: `${apiBling.defaults.baseURL}/orders`,
      method: 'delete',
      data: { data: [order] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Error ao remover Integração',
      local: 'OrderActionsDestroy',
    });

    return { orderErrors };
  }
}

export async function reprocess({ start_at = '', end_at = '' }) {
  try {
    await apiBling.patch('/orders', {}, { params: { start_at, end_at } });

    return await index({ search: '', order_by: 'id', order: 'desc' });
  } catch (error) {
    const orderErrors = errorHandler({
      error,
      title: 'Erro ao reprocessar Pedidos',
      local: 'OrdersActionIndex',
    });

    return { orderErrors };
  }
}
