import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useIntegrations } from '~/providers/productIntegration';
import { parseFormData } from '~/utils/integration';
import { setFormData } from '~/utils/form';

import { validate } from '~/validators/productintegration';

import { Container, DataContent, Button } from './styles';
import { Form, TextInput, Grid, Loading } from '~/components/Form';

function ProductIntegrationForm() {
  const formRef = useRef(null);
  const {
    product_integration,
    product_integrationErrors,
    clearState,
    store,
    update,
    product_integrationLoading,
  } = useIntegrations();

  const location = useLocation();

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedIntegration = {
        ...parsedData,
        req_id: '1',
      };

      if (product_integration.uuid)
        await update({
          product_integration: { ...product_integration, ...parsedIntegration },
        });
      else {
        await store({ product_integration: parsedIntegration });
      }
    }
  }

  useEffect(() => {
    if (product_integration.uuid) {
      const { type, ...parseIntegration } = product_integration;
      const formData = parseFormData(parseIntegration);

      setFormData({ formRef, formData });
    }
  }, [product_integration]);

  useEffect(() => {
    if (
      Object.keys(product_integrationErrors).length &&
      product_integrationErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(product_integrationErrors.validations);
  }, [product_integrationErrors]);

  useEffect(() => {
    return () => clearState({});
  }, [clearState]);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid />

        <DataContent>
          <Grid>
            <TextInput
              type="text"
              name="ecommerce_id"
              label="SKU Bling"
              required
              disabled={location?.state.showProductIntegration}
            />
            <TextInput
              type="text"
              name="erp_id"
              label="SKU SAP"
              required
              disabled={location?.state.showProductIntegration}
            />
          </Grid>
          <Grid>
            <TextInput
              type="text"
              name="cep_inicio"
              label="CEP Inicial"
              disabled={location?.state.showProductIntegration}
            />
            <TextInput
              type="text"
              name="cep_fim"
              label="CEP Final"
              disabled={location?.state.showProductIntegration}
            />
          </Grid>

          <Grid />
        </DataContent>

        <Button
          color="primary"
          disabled={product_integrationLoading}
          type="submit"
        >
          {!location?.state.newProductIntegration ? 'Confirmar' : 'Cadastrar'}
          {product_integrationLoading && <Loading color="secondary" />}
        </Button>
      </Form>
    </Container>
  );
}

export default ProductIntegrationForm;
