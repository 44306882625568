import apiBling from '~/config/apiBling';

import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiBling
      .get('/parameters', {
        params: { search, order_by, order },
      })
      .then(res => res.data);

    return { parameters: data };
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao listar Parâmetros',
      local: 'ParameterActionsIndex',
    });

    return { parameterErrors };
  }
}

export async function show({ parameterUuid = '' }) {
  try {
    const { data } = await apiBling
      .get(`/parameters/${parameterUuid}`)
      .then(res => res.data);

    return { parameter: data.parameter };
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao visualizar Parâmetro',
      local: 'ParameterActionsShow',
    });

    return { parameterErrors };
  }
}

export async function store({ parameter = {} }) {
  try {
    const { data } = await apiBling
      .post('/parameters', {
        data: [parameter],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...parameter, ...data[0].parameter };
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Parâmetro',
      local: 'ParameterActionsStore',
    });

    return { parameterErrors };
  }
}

export async function update({ parameter = {} }) {
  try {
    const { data } = await apiBling
      .put('/parameters', {
        data: [parameter],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return { ...parameter, ...data[0].parameter };
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao atualizar Parâmetro',
      local: 'ParameterActionsUpdate',
    });

    return { parameterErrors };
  }
}

export async function destroy({ parameter = {} }) {
  try {
    const { data } = await apiBling({
      url: `${apiBling.defaults.baseURL}/parameters`,
      method: 'delete',
      data: { data: [parameter] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const parameterErrors = errorHandler({
      error,
      title: 'Error ao remover Parâmetro',
      local: 'ParameterActionsDestroy',
    });

    return { parameterErrors };
  }
}
