import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/integration';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      ecommerce_id: Yup.string().required('Este campo é obrigatório'),
      erp_id: Yup.string().required('Este campo é obrigatório'),
    });

    const parsedData = parseDataToApi(data);

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
