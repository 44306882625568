import { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { useErrorHandler } from '../errorHandler';

import apiBling from '~/config/apiBling';

import * as actions from './actions';
import * as store from './store';

const INITIAL_STATE = {
  login: '',
  password: '',
  signed: false,
  user: {},
  token: {},
  logged_branch: {},
  current_accesses: {},
  authPageIndex: 0,
  authErrors: {},
  authLoading: false,
};

const AuthContext = createContext(INITIAL_STATE);

export function AuthProvider({ children }) {
  const history = useHistory();

  const { setErrorHandlerData } = useErrorHandler();

  const [data, setData] = useState(() => {
    const authData = store.getData();

    if (authData.token && authData.token.token && authData.signed) {
      apiBling.defaults.headers.authorization = `Bearer ${authData.token.token}`;
      apiBling.defaults.headers[
        'Refresh-Token'
      ] = `${authData.token.refreshToken}`;

      return { ...INITIAL_STATE, ...authData };
    }

    store.setData(INITIAL_STATE);

    return INITIAL_STATE;
  });

  const setAuthData = useCallback((newData = INITIAL_STATE) => {
    store.setData(newData);

    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const signIn = useCallback(
    async ({ login = '', password = '', branch_key = '', force = false }) => {
      setAuthData({ login, password, authErrors: {}, authLoading: true });

      const authData = await actions.signIn({
        login,
        password,
        branch_key,
        force,
      });

      if (authData.authErrors)
        setErrorHandlerData({
          error: {
            ...authData.authErrors,
            resolveFunction: () =>
              signIn({ login, password, branch_key, force: true }),
          },
        });
      else if (authData.token) authData.signed = true;
      else if (!data.authPageIndex)
        authData.authPageIndex = data.authPageIndex + 1;
      else {
        authData.login = '';
        authData.password = '';
      }

      setAuthData({ ...authData, authLoading: false });
    },
    [data.authPageIndex, setAuthData, setErrorHandlerData]
  );

  const changePassword = useCallback(
    async ({ password }) => {
      setAuthData({ authLoading: true });

      const authData = await actions.changePassword({ password });

      setAuthData({ authLoading: false });

      if (authData.authErrors) {
        setErrorHandlerData({
          error: {
            ...authData.authErrors,
            resolveFunction: () => changePassword({ password }),
          },
        });
      } else {
        toast.success('Senha alterada com sucesso!');

        history.goBack();
      }
    },
    [setAuthData, setErrorHandlerData, history]
  );

  const recoverPass = useCallback(
    async ({ email }) => {
      setAuthData({ authLoading: true });

      const authData = await actions.recoverPassword({ email });

      setAuthData({ authLoading: false });

      if (authData.authErrors) {
        setErrorHandlerData({
          error: {
            ...authData.authErrors,
            resolveFunction: () => recoverPass({ email }),
          },
        });
      } else {
        toast.success('Email enviado com sucesso');
      }
    },
    [setAuthData, setErrorHandlerData]
  );

  const signOut = useCallback(() => {
    store.setData(INITIAL_STATE);

    apiBling.defaults.headers.authorization = null;
    apiBling.defaults.headers['Refresh-Token'] = null;

    setAuthData(INITIAL_STATE);
  }, [setAuthData]);

  const refreshToken = useCallback(async () => {
    setAuthData({ authLoading: true });

    const authData = await actions.refreshToken();

    if (authData.authErrors) signOut();
    else setAuthData({ ...authData, authLoading: false });
  }, [setAuthData, signOut]);

  return (
    <AuthContext.Provider
      value={{
        ...data,
        setAuthData,
        signIn,
        signOut,
        refreshToken,
        changePassword,
        recoverPass,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}

AuthProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
