import { useState, useRef } from 'react';
import { useAuth } from '~/providers/auth';

import Logo from '~/assets/images/logo.png';
import { Form, TextInput } from '~/components/Form';
import {
  Container,
  LoginFormContainer,
  LogoImage,
  Button,
  Copyright,
  SwipeableViews,
  ForgotPassword,
  Loading,
} from './styles';

function Sign() {
  const [pageOrderIndex, setPageOrderIndex] = useState(0);

  const { authLoading, authErrors, signIn, recoverPass } = useAuth();

  const formLoginRef = useRef(null);
  const formForgotRef = useRef(null);

  async function handleSubmitLogin(data) {
    await signIn(data);
  }

  async function handleSubmitForgot(data) {
    await recoverPass(data);
    setPageOrderIndex(0);
  }

  return (
    <Container>
      <LoginFormContainer>
        <SwipeableViews index={pageOrderIndex}>
          <Form ref={formLoginRef} onSubmit={handleSubmitLogin}>
            <LogoImage src={Logo} alt="Logo Facile" />
            <TextInput
              error={authErrors.validations && authErrors.validations.login}
              name="login"
              label="Login"
            />
            <TextInput
              error={authErrors.validations && authErrors.validations.password}
              type="password"
              name="password"
              label="Senha"
            />

            <Button disabled={authLoading} type="submit">
              Acessar
              {authLoading && <Loading />}
            </Button>
            <ForgotPassword onClick={() => setPageOrderIndex(1)}>
              <p>Esqueci minha senha</p>
            </ForgotPassword>
          </Form>
          <Form ref={formForgotRef} onSubmit={handleSubmitForgot}>
            <h1 style={{ marginBottom: '1em' }}>Recuperar senha</h1>
            <TextInput name="email" label="E-mail" />

            <Button type="submit">Enviar</Button>
            <ForgotPassword onClick={() => setPageOrderIndex(0)}>
              <p>Voltar para a tela de Login</p>
            </ForgotPassword>
          </Form>
        </SwipeableViews>

        <Copyright>
          <p>Todos os direitos reservados</p>
        </Copyright>
      </LoginFormContainer>
    </Container>
  );
}

export default Sign;
