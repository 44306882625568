export function parseToView(product_integrations = []) {
  return product_integrations.map(product_integration => ({
    ...product_integration,
  }));
}

export function parseDataToApi(product_integration = {}) {
  return {
    ...product_integration,
  };
}

export function parseFormData(product_integration = {}) {
  return {
    ecommerce_id: product_integration.ecommerce_id,
    erp_id: product_integration.erp_id,
    cep_inicio: product_integration.cep_inicio,
    cep_fim: product_integration.cep_fim,
  };
}
