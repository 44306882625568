export function parseToView(orders = []) {
  return orders.map(order => ({
    ...order,
    // observacoes: `${order.observacoes.substring(0, 100)}...`,
  }));
}

export function parseDataToApi(order = {}) {
  return {
    ...order,
  };
}

export function parseFormData(order = {}) {
  return {
    cliente_ecommerce_id: order.cliente_ecommerce_id,
    cliente_erp_id: order.cliente_erp_id,
    pedido_venda_ecommerce_id: order.pedido_venda_ecommerce_id,
    pedido_venda_erp_id: order.pedido_venda_erp_id,
    pedido_compra_ecommerce_id: order.pedido_compra_ecommerce_id,
    pedido_compra_erp_id: order.pedido_compra_erp_id,
    cliente_nome: order.cliente_nome,
    data: order.data,
  };
}
