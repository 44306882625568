import { useEffect, useState, useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { FiEye, FiEdit2, FiTrash2 } from 'react-icons/fi';

import { Container } from './styles';

import { useIntegrations } from '~/providers/productIntegration';

import TableList from '~/components/TableList';

const headCells = [
  { id: 'id', label: 'Código' },
  { id: 'ecommerce_id', label: 'SKU Bling' },
  { id: 'erp_id', label: 'SKU SAP' },
  { id: 'cep_inicio', label: 'CEP Inicial' },
  { id: 'cep_fim', label: 'CEP Final' },
];

function ProductIntegration() {
  const history = useHistory();

  const {
    product_integrations,
    product_integrationListLoading,
    index,
    show,
    destroy,
  } = useIntegrations();

  const [loaded, setLoaded] = useState(false);

  const loadProductIntegrations = useCallback(
    async (search = '', order_by = 'id', order = 'asc') => {
      await index({ search, order_by, order });
    },
    [index]
  );

  const newItem = useMemo(
    () => ({
      label: 'Integrar Produto ',
      handleFunction: () =>
        history.push('/product-integrations/form', {
          newProductIntegration: true,
        }),
    }),
    [history]
  );

  const actions = useMemo(() => {
    const auxActions = [];

    auxActions.push({
      label: 'Visualizar',
      icon: <FiEye size={20} />,
      handleFunction: async product_integration => {
        history.push('/product-integrations/form', {
          showProductIntegration: true,
        });

        await show({ product_integrationUuid: product_integration.uuid });
      },
    });

    auxActions.push({
      label: 'Editar',
      icon: <FiEdit2 size={20} />,
      handleFunction: async product_integration => {
        history.push('product-integrations/form', {
          updateProductIntegration: true,
        });

        await show({ product_integrationUuid: product_integration.uuid });
      },
    });

    auxActions.push({
      label: 'Remover',
      icon: <FiTrash2 size={20} />,
      handleFunction: product_integration => {
        destroy({ product_integration });
      },
    });

    return auxActions;
  }, [history, show, destroy]);

  useEffect(() => {
    if (!loaded && product_integrations.length <= 1) loadProductIntegrations();

    setLoaded(true);
  }, [product_integrations, loadProductIntegrations, loaded]);

  return (
    <Container>
      <TableList
        newItem={newItem}
        headCells={headCells}
        data={product_integrations}
        loadData={loadProductIntegrations}
        loading={product_integrationListLoading}
        actions={actions}
      />
    </Container>
  );
}

export default ProductIntegration;
