import React, { useRef, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '~/providers/auth';
import { useParameter } from '~/providers/parameter';

import { validate } from '~/validators/parameter';

import { setFormData } from '~/utils/form';
import { parseFormData } from '~/utils/parameter';

import { Container, DataContent, Button } from './styles';
import { Form, TextInput, Grid, Loading } from '~/components/Form';

function ParameterForm() {
  const location = useLocation();

  const {
    parameter,
    parameterLoading,
    parameterErrors,
    store,
    update,
    clearState,
  } = useParameter();
  const { logged_branch } = useAuth();

  const formRef = useRef(null);

  const [canEdit, setCanEdit] = useState(false);

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedParameter = {
        ...parsedData,
        req_id: '1',
      };

      if (parameter.uuid)
        await update({
          parameter: { ...parameter, ...parsedParameter },
        });
      else {
        const { company_key, branch_key } = logged_branch;

        parsedParameter.company_key = company_key;
        parsedParameter.branch_key = branch_key;

        await store({ parameter: parsedParameter });
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(parameterErrors).length &&
      parameterErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(parameterErrors.validations);
  }, [parameterErrors]);

  useEffect(() => {
    if (parameter.uuid) {
      const auxParameter = { ...parameter };

      const formData = parseFormData(auxParameter);

      setFormData({ formRef, formData });

      if (location.state && !location.state.showParameter) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newParameter) {
      setCanEdit(true);
    }
  }, [parameter, location]);

  useEffect(() => () => clearState({}), [clearState]);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <DataContent>
          <Grid>
            <TextInput name="name" label="Nome" required disabled={!canEdit} />
            <TextInput
              name="parameter"
              label="Parâmetro"
              required
              disabled={!canEdit}
            />
          </Grid>
          <Grid>
            <TextInput
              name="description"
              label="Descrição"
              required
              disabled={!canEdit}
            />
            <TextInput
              name="value"
              label="Valor"
              required
              disabled={!canEdit}
            />
          </Grid>
        </DataContent>
        <Button
          disabled={parameterLoading || !canEdit}
          type="submit"
          variant="contained"
          color="secondary"
        >
          Confirmar
          {parameterLoading && <Loading />}
        </Button>
      </Form>
    </Container>
  );
}

export default ParameterForm;
