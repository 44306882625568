import { Switch, Route } from 'react-router-dom';

import NavigationHeader from '~/components/NavigationHeader';

import Dashboard from '~/pages/Dashboard';
import ProductIntegration from '~/pages/ProductIntegration';
import Users from '~/pages/Users';
import Branches from '~/pages/Branches';
import Parameters from '~/pages/Parameters';

import ProductIntegrationForm from '~/pages/ProductIntegration/Form';
import UserForm from '~/pages/Users/Form';
import BranchesForm from '~/pages/Branches/Form';
import ParametersForm from '~/pages/Parameters/Form';

export default function Routes() {
  return (
    <Switch>
      <NavigationHeader>
        <Route exact path="/" component={Dashboard} />
        <Route
          exact
          path="/product-integrations"
          component={ProductIntegration}
        />
        <Route
          path="/product-integrations/form"
          component={ProductIntegrationForm}
        />
        <Route exact path="/users" component={Users} />
        <Route path="/users/form" component={UserForm} />
        <Route exact path="/branches/" component={Branches} />
        <Route path="/branches/form" component={BranchesForm} />
        <Route exact path="/parameters/" component={Parameters} />
        <Route path="/parameters/form" component={ParametersForm} />
      </NavigationHeader>
    </Switch>
  );
}
