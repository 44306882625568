import apiBling from '~/config/apiBling';

import { parseToView } from '~/utils/user';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiBling
      .get('/users', { params: { search, order_by, order } })
      .then(res => res.data);

    return { users: parseToView(data) };
  } catch (error) {
    const userErrors = errorHandler({
      error,
      title: 'Error ao listar Usuários',
      local: 'UserActionsIndex',
    });

    return { userErrors };
  }
}

export async function show({ userUuid = '' }) {
  try {
    const { data } = await apiBling
      .get(`/users/${userUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.user;

    return { user: parseToView([{ ...data.user, ...auxData }])[0] };
  } catch (error) {
    const userErrors = errorHandler({
      error,
      title: 'Error ao visualizar Usuário',
      local: 'UserActionsShow',
    });

    return { userErrors };
  }
}

export async function store({ user = {} }) {
  try {
    const { data } = await apiBling
      .post('/users', {
        data: [user],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...user, ...data[0].user }])[0];
  } catch (error) {
    const userErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Usuário',
      local: 'UserActionsStore',
    });

    return { userErrors };
  }
}

export async function update({ user = {} }) {
  try {
    const { data } = await apiBling
      .put('/users', {
        data: [user],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...user, ...data[0].user }])[0];
  } catch (error) {
    const userErrors = errorHandler({
      error,
      title: 'Error ao atualizar Usuário',
      local: 'UserActionsUpdate',
    });

    return { userErrors };
  }
}

export async function destroy({ user = {} }) {
  try {
    const { data } = await apiBling({
      url: `${apiBling.defaults.baseURL}/users`,
      method: 'delete',
      data: { data: [user] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const userErrors = errorHandler({
      error,
      title: 'Error ao remover Usuário',
      local: 'UserActionsDestroy',
    });

    return { userErrors };
  }
}

export async function newUser() {
  try {
    const { data } = await apiBling.get('/new-user').then(res => res.data);

    return data;
  } catch (error) {
    const userErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para novo Cliente',
      local: 'UserActionsNewUser',
    });

    return { userErrors };
  }
}
