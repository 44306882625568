import { createContext, useState, useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { useErrorHandler } from '../errorHandler';

import * as actions from './actions';

const INITIAL_STATE = {
  users: [],
  user: {},
  userErrors: {},
  userLoading: false,
  userListLoading: false,
};

const UserContext = createContext(INITIAL_STATE);

export function UserProvider({ children }) {
  const { setErrorHandlerData } = useErrorHandler();
  const history = useHistory();

  const [data, setData] = useState(INITIAL_STATE);

  const setUserData = useCallback((newData = INITIAL_STATE) => {
    setData(oldData => ({ ...oldData, ...newData }));
  }, []);

  const index = useCallback(
    async ({ search = '', order_by = '', order = '' }) => {
      setUserData({ userListLoading: true });

      const userData = await actions.index({
        search,
        order_by,
        order,
      });

      if (userData.userErrors)
        setErrorHandlerData({
          error: {
            ...userData.userErrors,
            resolveFunction: () => index({ search, order_by, order }),
          },
        });

      setUserData({ ...userData, userListLoading: false });
    },
    [setUserData, setErrorHandlerData]
  );

  const show = useCallback(
    async ({ userUuid = '' }) => {
      setUserData({ userLoading: true });

      const userData = await actions.show({ userUuid });

      if (userData.userErrors) {
        setErrorHandlerData({
          error: {
            ...userData.userErrors,
            resolveFunction: () => show({ userUuid }),
          },
        });

        history.goBack();
      }

      setUserData({ ...userData, userLoading: false });
    },
    [setUserData, setErrorHandlerData, history]
  );

  const store = useCallback(
    async ({ user = {} }) => {
      setUserData({ userLoading: true });

      const userData = await actions.store({ user });

      if (userData.userErrors) {
        setErrorHandlerData({
          error: {
            ...userData.userErrors,
            resolveFunction: () => store({ user }),
          },
        });

        setUserData({ ...userData, userLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          users: [userData, ...oldData.users],
          userLoading: false,
        }));

        history.goBack();

        toast.success('Usuário cadastrado com sucesso!');
      }
    },
    [setUserData, setErrorHandlerData, history]
  );

  const update = useCallback(
    async ({ user = {} }) => {
      setUserData({ userLoading: true });

      const userData = await actions.update({ user });

      if (userData.userErrors) {
        setErrorHandlerData({
          error: {
            ...userData.userErrors,
            resolveFunction: () => update({ user }),
          },
        });

        setUserData({ ...userData, userLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          users: [
            userData,
            ...oldData.users.filter(auxUser => auxUser.uuid !== user.uuid),
          ],
          userLoading: false,
        }));

        history.goBack();

        toast.success('Usuário atualizado com sucesso!');
      }
    },
    [setUserData, setErrorHandlerData, history]
  );

  const destroy = useCallback(
    async ({ user = {} }) => {
      setUserData({ userListLoading: true });

      const userData = await actions.destroy({ user });

      if (userData.userErrors) {
        setErrorHandlerData({
          error: {
            ...userData.userErrors,
            resolveFunction: () => destroy({ user }),
          },
        });

        setUserData({ ...userData, userListLoading: false });
      } else {
        setData(oldData => ({
          ...oldData,
          users: [
            ...oldData.users.filter(auxUser => auxUser.uuid !== user.uuid),
          ],
          userListLoading: false,
        }));

        toast.success('Usuário removido com sucesso!');
      }
    },
    [setUserData, setErrorHandlerData]
  );

  const newUser = useCallback(async () => {
    setUserData({ userLoading: true });

    const userData = await actions.newUser();

    if (userData.userErrors)
      setErrorHandlerData({
        error: {
          ...userData.userErrors,
          resolveFunction: () => newUser(),
        },
      });

    setUserData({ userLoading: false });

    return { user_groups: [], states: [], users: [], ...userData };
  }, [setUserData, setErrorHandlerData]);

  const clearState = useCallback(({ all = false }) => {
    setData(oldData => {
      if (all) return INITIAL_STATE;
      return {
        ...oldData,
        user: {},
        userErrors: {},
        userLoading: false,
        userListLoading: false,
      };
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        ...data,
        setUserData,
        index,
        show,
        store,
        update,
        destroy,
        newUser,
        clearState,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);

  if (!context) throw new Error('useUser must be used within an UserProvider');

  return context;
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
