import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { useUser } from '~/providers/user';
import { parseFormData } from '~/utils/user';
import { setFormData } from '~/utils/form';

import { validate } from '~/validators/user';

import { Container, DataContent, Button } from './styles';
import { Form, TextInput, RadioGroup, Grid, Loading } from '~/components/Form';

const customerTypes = [
  { value: 'F', label: 'Física' },
  { value: 'J', label: 'Jurídica' },
];

function UserForm() {
  const formRef = useRef(null);
  const { user, clearState, store, update, userLoading } = useUser();
  const location = useLocation();

  const [userType, setUserType] = useState('');

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      const parsedUser = {
        ...parsedData,
        req_id: '1',
      };

      if (user.uuid) await update({ user: { ...user, ...parsedUser } });
      else {
        await store({ user: parsedUser });
      }
    }
  }

  const handleChangeType = useCallback(newType => {
    setUserType(newType);
  }, []);

  useEffect(() => {
    if (user.uuid) {
      const { type, ...parseUser } = user;
      const typeInputRef = formRef.current.getFieldRef('type');
      typeInputRef.handleChange({ target: { value: type } });
      const formData = parseFormData(parseUser);
      setFormData({ formRef, formData });
    }
  }, [user]);

  useEffect(() => {
    return () => clearState({});
  }, [clearState]);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <DataContent>
          <Grid>
            <RadioGroup
              name="type"
              label="Tipo"
              options={customerTypes}
              onChange={handleChangeType}
              required
              disabled={location?.state.showUser}
            />

            <TextInput
              name="cpf_cnpj"
              label={
                !userType ? 'CPF / CNPJ' : userType === 'F' ? 'CPF' : 'CNPJ' // eslint-disable-line
              }
              helperText={!userType ? 'Selecione um Tipo antes' : ''}
              required
              disabled={location?.state.showUser}
            />
            <TextInput
              name="login"
              label="Login"
              required
              disabled={location?.state.showUser}
            />
          </Grid>

          <Grid>
            <TextInput
              type="text"
              name="name"
              label="Nome"
              required
              disabled={location?.state.showUser}
            />
            <TextInput
              type="text"
              name="email"
              label="Email"
              required
              disabled={location?.state.showUser}
            />

            <TextInput
              type="password"
              name="password"
              label="Senha"
              disabled={location?.state.showUser || location?.state.newUser}
              helperText={
                location?.state.newUser ? 'Senha será enviada por email' : ''
              }
            />
            <TextInput
              type="password"
              name="password_confirmation"
              label="Confirmar Senha"
              disabled={location?.state.showUser || location?.state.newUser}
              helperText={
                location?.state.newUser ? 'Senha será enviada por email' : ''
              }
            />
          </Grid>
        </DataContent>

        <Button disabled={userLoading} type="submit">
          {!location?.state.newUser ? 'Confirmar' : 'Cadastrar'}
          {userLoading && <Loading color="secondary" />}
        </Button>
      </Form>
    </Container>
  );
}

export default UserForm;
