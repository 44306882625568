import PropTypes from 'prop-types';
import Row from './Row';

import { Body } from './styles';

function TableListBody({
  rows,
  page,
  rowsPerPage,
  headCells,
  otherCells,
  observationCells,
  actions,
  select,
}) {
  return (
    <Body>
      {rows
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map(row => (
          <Row
            key={row.id}
            row={row}
            headCells={headCells}
            otherCells={otherCells}
            observationCells={observationCells}
            actions={actions}
            selec
            select={select}
          />
        ))}
    </Body>
  );
}

export default TableListBody;

const actionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    disabled: PropTypes.bool,
    handleFunction: PropTypes.func.isRequired,
  })
);

const selectPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  handleFunction: PropTypes.func.isRequired,
});

const cellsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    image: PropTypes.bool,
  })
);

TableListBody.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({})),
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  observationCells: cellsPropType,
  actions: actionsPropType,
  select: selectPropType,
};

TableListBody.defaultProps = {
  otherCells: null,
  observationCells: null,
  rows: [],
  actions: null,
  select: true,
};
