import { toast } from 'react-toastify';

import apiVinilico from '~/config/apiBling';

export default async function indexCities({ state_id }) {
  try {
    const { data } = await apiVinilico
      .get(`/states/${state_id}`)
      .then(res => res.data);

    return data;
  } catch (error) {
    toast.warn('Error ao buscar Cidades do Estado');

    return { cities: [] };
  }
}
