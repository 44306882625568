import apiBling from '~/config/apiBling';

import { parseToView } from '~/utils/integration';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiBling
      .get('/product-integrations', { params: { search, order_by, order } })
      .then(res => res.data);

    return { product_integrations: parseToView(data) };
  } catch (error) {
    const product_integrationErrors = errorHandler({
      error,
      title: 'Error ao listar Integrações',
      local: 'IntegrationActionsIndex',
    });

    return { product_integrationErrors };
  }
}

export async function show({ product_integrationUuid = '' }) {
  try {
    const { data } = await apiBling
      .get(`/product-integrations/${product_integrationUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.product_integration;

    return {
      product_integration: parseToView([
        { ...data.product_integration, ...auxData },
      ])[0],
    };
  } catch (error) {
    const product_integrationErrors = errorHandler({
      error,
      title: 'Error ao visualizar Integração',
      local: 'IntegrationActionsShow',
    });

    return { product_integrationErrors };
  }
}

export async function store({ product_integration = {} }) {
  try {
    const { data } = await apiBling
      .post('/product-integrations', {
        data: [product_integration],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([
      { ...product_integration, ...data[0].product_integration },
    ])[0];
  } catch (error) {
    const product_integrationErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Integração',
      local: 'IntegrationActionsStore',
    });

    return { product_integrationErrors };
  }
}

export async function update({ product_integration = {} }) {
  try {
    const { data } = await apiBling
      .put('/product-integrations', {
        data: [product_integration],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([
      { ...product_integration, ...data[0].product_integration },
    ])[0];
  } catch (error) {
    const product_integrationErrors = errorHandler({
      error,
      title: 'Error ao atualizar Integração',
      local: 'IntegrationActionsUpdate',
    });

    return { product_integrationErrors };
  }
}

export async function destroy({ product_integration = {} }) {
  try {
    const { data } = await apiBling({
      url: `${apiBling.defaults.baseURL}/product-integrations`,
      method: 'delete',
      data: { data: [product_integration] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const product_integrationErrors = errorHandler({
      error,
      title: 'Error ao remover Integração',
      local: 'IntegrationActionsDestroy',
    });

    return { product_integrationErrors };
  }
}

export async function newProductIntegration() {
  try {
    const { data } = await apiBling
      .get('/new-product-integration')
      .then(res => res.data);

    return data;
  } catch (error) {
    const product_integrationErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para novo Produto de Integração',
      local: 'IntegrationActionsNewUser',
    });

    return { product_integrationErrors };
  }
}
