export default {
  title: 'light',

  colors: {
    primary: '#545960',
    secondary: '#FB6602',
    primaryLight: '#818A92',

    backFirst: '#EFEFEF',

    textFirst: '#aaa',
  },
};
