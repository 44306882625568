import { useState, useEffect, useRef, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import { FiSearch } from 'react-icons/fi';

import { useBranches } from '~/providers/branches';
import { useErrorHandler } from '~/providers/errorHandler';

import {
  Form,
  TextInput,
  RadioGroup,
  Grid,
  Selector,
  Loading,
} from '~/components/Form';

import { validate } from '~/validators/branches';

import { parseFormData } from '~/utils/branches';
import { setFormData } from '~/utils/form';
import {
  cnpjMask,
  cpfMask,
  zipCodeMask,
  phoneMask,
  cellMask,
  numberUnmask,
} from '~/utils/mask';

import SearchCnpjService from '~/services/searchCnpj';
import SearchZipCodeService from '~/services/searchZipCode';
import indexCities from '~/services/indexCities';

import { Container, DataContent, Button, SwitchContent } from './styles';

const branchTypes = [
  { value: 'F', label: 'Física' },
  { value: 'J', label: 'Jurídica' },
];

const branchStatus = [
  { value: '1', label: 'Ativo' },
  { value: '2', label: 'Inativo' },
];

function BranchForm() {
  const {
    branch,
    store,
    update,
    branchesLoading,
    branchesErrors,
    clearState,
    newBranch,
  } = useBranches();
  const { setErrorHandlerData } = useErrorHandler();

  const [type, setType] = useState('');
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [exempted, setExempted] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [loadingCities, setLoadingCities] = useState(false);
  const [loadingCnpj, setLoadingCnpj] = useState(false);
  const [loadingZipCode, setLoadingZipCode] = useState(false);

  const formRef = useRef(null);
  const location = useLocation();

  const handleSubmit = useCallback(
    async data => {
      const { errorMessages, ...parsedData } = await validate(data);
      if (errorMessages && formRef.current)
        formRef.current.setErrors(errorMessages);
      else {
        const findedState = states.find(state => state.id === data.state_id);
        const findedCity = cities.find(city => city.id === data.city_id);

        const parsedBranch = {
          ...parsedData,
          req_id: '1',
          state: findedState,
          city: findedCity,
        };
        if (branch.uuid)
          await update({
            branch: { ...branch, ...parsedBranch },
          });
        else {
          await store({ branch: parsedBranch });
        }
      }
    },
    [states, cities, update, store, branch]
  );

  const handleChangeExempted = useCallback(event => {
    setExempted(event.target.checked);
    setFormData({ formRef, formData: { state_inscription: '' } });
  }, []);

  const handleChangeType = useCallback(newType => {
    setType(newType);
    setFormData({ formRef, formData: { cpf_cnpj: '' } });
  }, []);

  async function handleSearchZipCode() {
    await SearchZipCodeService({
      setLoadingZipCode,
      formRef,
      setErrorHandlerData,
      states,
    });
  }

  async function handleSearchCnpj() {
    await SearchCnpjService({
      setLoadingCnpj,
      formRef,
      setErrorHandlerData,
      handleSearchZipCode,
    });
  }

  async function handleChangeState(state) {
    if (state) {
      setLoadingCities(true);
      const { cities: auxCities } = await indexCities({ state_id: state.id });
      setLoadingCities(false);

      setCities(auxCities);

      const city_id =
        formRef.current && formRef.current.getFieldValue('city_id');

      if (city_id) {
        const findedCity = auxCities.find(city => city.id === city_id);

        if (formRef.current) {
          const cityInputRef = formRef.current.getFieldRef('city_id');
          cityInputRef.handleChange(findedCity);
        }
      }
    } else {
      setCities([]);

      if (formRef.current) {
        const cityInputRef = formRef.current.getFieldRef('city_id');
        cityInputRef.handleChange(null);
      }
    }
  }

  useEffect(() => {
    if (
      Object.keys(branchesErrors).length &&
      branchesErrors.validations &&
      formRef.current
    )
      formRef.current.setErrors(branchesErrors.validations);
  }, [branchesErrors]);

  useEffect(() => {
    async function loadData() {
      const { states: auxStates } = await newBranch();

      setStates(auxStates);
    }

    if (branch.uuid) {
      const auxBranch = { ...branch };

      const {
        states: auxStates,
        cities: auxCities,
        type: auxType,
        status_code,
        state,
        city,
        ...branchData
      } = auxBranch;

      setStates(auxStates);
      setCities(auxCities);

      if (formRef.current) {
        const typeInputRef = formRef.current.getFieldRef('type');
        typeInputRef.handleChange({ target: { value: auxType } });

        const statusCodeInputRef = formRef.current.getFieldRef('status_code');
        statusCodeInputRef.handleChange({ target: { value: String(status_code) } }); // prettier-ignore

        const stateInputRef = formRef.current.getFieldRef('state_id');
        stateInputRef.handleChange(state);

        const cityInputRef = formRef.current.getFieldRef('city_id');
        cityInputRef.handleChange(city);

        if (!auxBranch.state_inscription) setExempted(true);
      }

      const formData = parseFormData(branchData);

      setFormData({ formRef, formData });
      if (location.state?.updateBranch) setCanEdit(true);

      window.history.replaceState(null, '');
    } else if (!location.state || location.state.newBranch) {
      loadData();
      setCanEdit(true);
    }
  }, [branch, newBranch, location]);

  useEffect(() => {
    return () => clearState({});
  }, [clearState]);

  return (
    <Container>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid>
          <RadioGroup
            name="type"
            label="Tipo"
            options={branchTypes}
            onChange={handleChangeType}
            required
            disabled={!canEdit}
          />
        </Grid>

        <DataContent>
          <Grid>
            <TextInput
              name="cpf_cnpj"
              label={!type ? 'CPF / CNPJ' : type === 'F' ? 'CPF' : 'CNPJ'} // eslint-disable-line
              helperText={!type ? 'Selecione um Tipo antes' : ''}
              mask={type === 'F' ? cpfMask : cnpjMask}
              required
              disabled={!canEdit}
              endAdornment={
                type && type === 'J' ? (
                  <IconButton
                    size="small"
                    edge="end"
                    disabled={loadingCnpj || !canEdit}
                    onClick={handleSearchCnpj}
                  >
                    {loadingCnpj ? <Loading /> : <FiSearch />}
                  </IconButton>
                ) : null
              }
            />
          </Grid>

          <Grid>
            <TextInput
              name="state_inscription"
              label="Inscrição Estadual"
              disabled={exempted || !canEdit}
              maxLength={20}
            />
          </Grid>

          <Grid>
            <SwitchContent row>
              <FormControlLabel
                control={
                  <Switch
                    checked={exempted}
                    onChange={handleChangeExempted}
                    color="primary"
                    disabled={!canEdit}
                  />
                }
                label="ISENTO"
              />
            </SwitchContent>
          </Grid>

          <Grid>
            <TextInput
              name="erp_id"
              label="Código Sap"
              required
              disabled={!canEdit}
            />
          </Grid>
          <Grid>
            <TextInput
              name="ecommerce_id"
              required
              label="Código Bling"
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput
              name="first_name"
              label="Razão Social"
              required
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput
              name="last_name"
              label="Nome Fantasia"
              disabled={!canEdit}
            />
          </Grid>
          <Grid>
            <TextInput
              name="email"
              label="E-mail"
              required
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput
              name="ddd1"
              label="DDD 1"
              mask={value => numberUnmask(value)}
              required
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput
              name="telephone1"
              mask={value =>
                numberUnmask(value).length <= 8
                  ? phoneMask(value)
                  : cellMask(value)
              }
              label="Telefone 1"
              required
              disabled={!canEdit}
            />
          </Grid>
          <Grid>
            <TextInput
              name="ddd2"
              label="DDD 2"
              mask={value => numberUnmask(value)}
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput
              name="telephone2"
              mask={value =>
                numberUnmask(value).length <= 8
                  ? phoneMask(value)
                  : cellMask(value)
              }
              label="Telefone 2"
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput
              name="zip_code"
              label="CEP"
              required
              disabled={!canEdit}
              mask={zipCodeMask}
              endAdornment={
                <IconButton
                  size="small"
                  edge="end"
                  disabled={loadingZipCode || !canEdit}
                  onClick={handleSearchZipCode}
                >
                  {loadingZipCode ? <Loading /> : <FiSearch />}
                </IconButton>
              }
            />
          </Grid>

          <Grid>
            <Selector
              name="state_id"
              label="Estado"
              idColumn="id"
              nameColumn="name"
              options={states}
              onChange={handleChangeState}
              loading={branchesLoading}
              required
              disabled={!canEdit}
            />
          </Grid>
          <Grid>
            <Selector
              name="city_id"
              label="Cidade"
              idColumn="id"
              nameColumn="name"
              options={cities}
              loading={loadingCities || branchesLoading}
              required
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput
              name="neighborhood"
              label="Bairro"
              required
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput
              name="address"
              label="Endereço"
              required
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <TextInput name="number" label="Número" disabled={!canEdit} />
          </Grid>

          <Grid>
            <TextInput
              name="complement"
              label="Complemento"
              disabled={!canEdit}
            />
          </Grid>

          <Grid>
            <RadioGroup
              name="status_code"
              label="Status"
              options={branchStatus}
              required
              disabled={!canEdit}
            />
          </Grid>
        </DataContent>

        <Button type="submit" disabled={!canEdit}>
          {branchesLoading ? <Loading color="secondary" /> : 'Enviar'}
        </Button>
      </Form>
    </Container>
  );
}

export default BranchForm;
