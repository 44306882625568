import styled from 'styled-components';
import TableBody from '@material-ui/core/TableBody';

export const Container = styled.div`
  width: 100%;
  min-width: 700px;
  position: 'relative';
`;

export const LoadingWrapper = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 70px;
  bottom: 70px;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
`;

export const Body = styled(TableBody)`
  background: #fff;
`;
