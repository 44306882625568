import apiBling from '~/config/apiBling';

import { parseToView } from '~/utils/branches';
import errorHandler from '~/utils/errorHandler';

export async function index({ search = '', order_by = '', order = '' }) {
  try {
    const { data } = await apiBling
      .get('/branches', { params: { search, order_by, order } })
      .then(res => res.data);
    return { branches: parseToView(data) };
  } catch (error) {
    const branchesErrors = errorHandler({
      error,
      title: 'Error ao listar Filiais',
      local: 'BranchesActionsIndex',
    });

    return { branchesErrors };
  }
}

export async function show({ branchUuid = '' }) {
  try {
    const { data } = await apiBling
      .get(`/branches/${branchUuid}`)
      .then(res => res.data);

    const auxData = { ...data };
    delete auxData.branch;

    return {
      branch: parseToView([{ ...data.branch, ...auxData }])[0],
    };
  } catch (error) {
    const branchesErrors = errorHandler({
      error,
      title: 'Error ao visualizar Filial',
      local: 'BranchesActionsShow',
    });

    return { branchesErrors };
  }
}

export async function store({ branch = {} }) {
  try {
    const { data } = await apiBling
      .post('/branches', {
        data: [branch],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }
    return parseToView([{ ...branch, ...data[0].branch }])[0];
  } catch (error) {
    const branchesErrors = errorHandler({
      error,
      title: 'Error ao cadastrar Filial',
      local: 'BranchesActionsStore',
    });

    return { branchesErrors };
  }
}

export async function update({ branch = {} }) {
  try {
    const { data } = await apiBling
      .put('/branches', {
        data: [branch],
      })
      .then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return parseToView([{ ...branch, ...data[0].branch }])[0];
  } catch (error) {
    const branchesErrors = errorHandler({
      error,
      title: 'Error ao atualizar Filial',
      local: 'BranchesActionsUpdate',
    });

    return { branchesErrors };
  }
}

export async function destroy({ branch = {} }) {
  try {
    const { data } = await apiBling({
      url: `${apiBling.defaults.baseURL}/branches`,
      method: 'delete',
      data: { data: [branch] },
    }).then(res => res.data);

    if (data[0].status !== 200) {
      const { error } = data[0];

      throw error;
    }

    return {};
  } catch (error) {
    const branchesErrors = errorHandler({
      error,
      title: 'Error ao remover Filial',
      local: 'BranchesActionsDestroy',
    });

    return { branchesErrors };
  }
}

export async function newBranch() {
  try {
    const { data } = await apiBling.get('/new-branch').then(res => res.data);

    return data;
  } catch (error) {
    const branchesErrors = errorHandler({
      error,
      title: 'Error ao pegar dados para novo cadastro de Filial',
      local: 'BranchesActionsNewUser',
    });

    return { branchesErrors };
  }
}
