import apiBling from '~/config/apiBling';

import { sessionParse } from '~/utils/auth';
import errorHandler from '~/utils/errorHandler';

export async function signIn({ login = '', password = '', force = false }) {
  try {
    const { data } = await apiBling.post(
      '/sessions',
      {
        login,
        password,
      },
      { headers: { force } }
    );
    if (data.token) {
      apiBling.defaults.headers.authorization = `Bearer ${data.token.token}`;
      apiBling.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;
    }

    const sessionParsed = sessionParse(data.accesses);

    return { ...data, ...sessionParsed };
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar ao entrar',
      local: 'AuthActionsSignIn',
    });

    return { authErrors };
  }
}

export async function refreshToken() {
  try {
    const { data } = await apiBling.put('/sessions');

    apiBling.defaults.headers.authorization = `Bearer ${data.token.token}`;
    apiBling.defaults.headers['Refresh-Token'] = `${data.token.refreshToken}`;

    const sessionParsed = sessionParse(
      data.accesses,
      data.logged_branch.branch_key
    );
    delete sessionParsed.companies;
    delete sessionParsed.branches;

    return { ...data, ...sessionParsed };
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar entrar novamente',
      local: 'AuthActionsRefreshToken',
    });

    return { authErrors };
  }
}

export async function changePassword({ password }) {
  try {
    const data = await apiBling
      .post('/change-password', { password, password_confirmation: password })
      .then(res => res.data);

    return data;
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar alterar a senha',
      local: 'AuthActionsChangePassword',
    });

    return { authErrors };
  }
}

export async function recoverPassword({ email }) {
  try {
    const data = await apiBling
      .post('/passwords', { email })
      .then(res => res.data);

    return data;
  } catch (error) {
    const authErrors = errorHandler({
      error,
      title: 'Error tentar alterar a senha',
      local: 'AuthActionsChangePassword',
    });

    return { authErrors };
  }
}
