import { useRef } from 'react';
import PropTypes from 'prop-types';
import { useOrder } from '~/providers/orders';
import { validate } from '~/validators/reprocess';
import { Dialog, DialogTitle, Button } from './styles';
import { Form, DatePicker, Grid, Loading } from '~/components/Form';

export default function ReprocessDialog({ setOrder, showDialog, setShowDialog }) {
  const formRef = useRef(null);
  const { reprocess, orderLoading } = useOrder();

  const handleClose = () => {
    setShowDialog(false);
  };

  async function handleSubmit(data) {
    const { errorMessages, ...parsedData } = await validate(data);

    if (errorMessages && formRef.current)
      formRef.current.setErrors(errorMessages);
    else {
      handleClose();

      await reprocess(parsedData);
      setOrder('desc')
    }
  }

  return (
    <Dialog onClose={handleClose} open={showDialog}>
      <DialogTitle>Reprocessar Pedidos</DialogTitle>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <Grid>
          <DatePicker required name="start_at" label="Data de Início" />
          <DatePicker required name="end_at" label="Data de Fim" />

          <Button
            disabled={orderLoading}
            type="submit"
            variant="contained"
            color="primary"
          >
            Confirmar
            {orderLoading && <Loading color="secondary" />}
          </Button>
        </Grid>
      </Form>
    </Dialog>
  );
}

ReprocessDialog.propTypes = {
  showDialog: PropTypes.bool.isRequired,
  setShowDialog: PropTypes.func.isRequired,
};
