import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import { FiMenu, FiArrowUp, FiArrowDown } from 'react-icons/fi';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

export default function Row({
  row,
  headCells,
  otherCells,
  observationCells,
  actions,
  select,
}) {
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMenu = action => {
    action.handleFunction(row);
    handleClose();
  };

  const handleSelectItem = () => {
    select.handleFunction(row);
  };

  return (
    <>
      <TableRow hover className={classes.root}>
        {otherCells ? (
          <TableCell>
            <IconButton size="small" onClick={() => setOpen(!open)}>
              {open ? <FiArrowUp /> : <FiArrowDown />}
            </IconButton>
          </TableCell>
        ) : (
          <TableCell />
        )}
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            <div
              style={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
              }}
            >
              {headCell.image ? (
                <img
                  src={row[headCell.id]}
                  style={{ borderRadius: 50, width: '50px', height: '50px' }}
                  alt="imagem"
                />
              ) : (
                row[headCell.id]
              )}
            </div>
          </TableCell>
        ))}
        {select ? (
          <TableCell align="center">
            <IconButton onClick={handleSelectItem}>
              {row.icon || select.icon}
            </IconButton>
          </TableCell>
        ) : (
          actions && (
            <TableCell align="center">
              <IconButton onClick={handleOpenMenu}>
                <FiMenu />
              </IconButton>
              {!!actions.length && (
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {actions.map(action => (
                    <MenuItem
                      key={action.label}
                      onClick={() => handleSelectMenu(action)}
                      disabled={!!action.disabled}
                    >
                      {action.icon}
                      {action.label}
                    </MenuItem>
                  ))}
                </Menu>
              )}
            </TableCell>
          )
        )}
      </TableRow>
      {otherCells && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headCells.length + (otherCells ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Outras Informações
                </Typography>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {otherCells.map(otherCell => (
                        <TableCell
                          key={otherCell.id}
                          align={otherCell.numeric ? 'right' : 'left'}
                          padding={
                            otherCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          {otherCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {otherCells.map(otherCell => (
                        <TableCell
                          key={otherCell.id}
                          align={otherCell.numeric ? 'right' : 'left'}
                          padding={
                            otherCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          <div
                            style={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {row.others[otherCell.id]}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      {observationCells && (
        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={headCells.length + (observationCells ? 2 : 1)}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      {observationCells.map(observationCell => (
                        <TableCell
                          key={observationCell.id}
                          align={observationCell.numeric ? 'right' : 'left'}
                          padding={
                            observationCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          {observationCell.label}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {observationCells.map(observationCell => (
                        <TableCell
                          key={observationCell.id}
                          align={observationCell.numeric ? 'right' : 'left'}
                          padding={
                            observationCell.disablePadding ? 'none' : 'default'
                          }
                        >
                          <div
                            style={{
                              display: '-webkit-box',
                              overflow: 'hidden',
                              WebkitLineClamp: 2,
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {row.others[observationCell.id]}
                          </div>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}

const actionsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    label: PropTypes.string.isRequired,
    icon: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]),
    disabled: PropTypes.bool,
    handleFunction: PropTypes.func.isRequired,
  })
);

const selectPropType = PropTypes.shape({
  label: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  disabled: PropTypes.bool,
  handleFunction: PropTypes.func.isRequired,
});

const cellsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    numeric: PropTypes.bool,
    disablePadding: PropTypes.bool,
    image: PropTypes.bool,
  })
);

Row.propTypes = {
  row: PropTypes.shape().isRequired,
  headCells: cellsPropType.isRequired,
  otherCells: cellsPropType,
  observationCells: cellsPropType,
  actions: actionsPropType,
  select: selectPropType,
};

Row.defaultProps = {
  otherCells: null,
  observationCells: null,
  actions: null,
  select: null,
};
