import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

import Btn from '@material-ui/core/Button';
import SwipViews from 'react-swipeable-views';
import Lnk from '@material-ui/core/Link';

export { default as Checkbox } from '@material-ui/core/Checkbox';
export { default as FormControlLabel } from '@material-ui/core/FormControlLabel';

export const Container = styled.div`
  display: flex;
  flex: 1;
  margin: 0 auto;
  place-items: center;
`;

export const LoginFormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2em;
  height: 100vh;
  align-items: center;
`;

export const LogoImage = styled.img`
  width: 200px;
`;

export const Copyright = styled.div`
  margin-top: auto;
  color: ${props => props.theme.colors.primary};
`;

export const Button = styled(Btn).attrs({
  variant: 'contained',
  color: 'secondary',
})`
  .MuiButtonBase-root {
    margin-top: 1em;
  }
`;

export const Loading = styled(CircularProgress).attrs({
  size: 24,
})`
  position: absolute;
`;

export const SwipeableViews = styled(SwipViews).attrs({
  disabled: true,
})`
  margin-top: auto;
  .react-swipeable-view-container {
    height: 100%;
    place-items: center;
    margin-top: auto;
    width: 500px;
    img {
      max-width: 350px;
      margin: 0 auto;
      margin-bottom: 1em;
    }
    color: ${props => props.theme.colors.primary};
  }
  .react-swipeable-view-container > * {
    display: flex;
    place-items: center;
    margin-top: auto;
    width: 500px;
  }
`;

export const ForgotPassword = styled(Lnk).attrs({})`
  cursor: pointer;
  p {
    margin-top: 1em;
  }
`;
