import {
  cpfMask,
  cnpjMask,
  numberUnmask,
  numberMask,
  valueUnmask,
} from './mask';

export function parseToView(branches = []) {
  return branches.map(branch => {
    return {
      ...branch,
      cpf_cnpj:
        branch.type === 'F'
          ? cpfMask(branch.cpf_cnpj)
          : cnpjMask(branch.cpf_cnpj),
      erp_id: branch.erp_id,
      ecommerce_id: branch.ecommerce_id,
    };
  });
}

export function parseDataToApi(branch = {}) {
  return {
    ...branch,
    cpf_cnpj: numberUnmask(branch.cpf_cnpj),
    state_inscription: branch.state_inscription || null,
    last_name: branch.last_name || null,
    telephone1: numberUnmask(branch.telephone1),
    ddd2: branch.ddd2 || null,
    telephone2: numberUnmask(branch.telephone2) || null,
    zip_code: numberUnmask(branch.zip_code),
    number: branch.number || null,
    complement: branch.complement || null,
    credit_limit: valueUnmask(branch.credit_limit) || null,
    seller2_uuid: branch.seller2_uuid || null,
    status_code: numberMask(branch.status_code),
    erp_id: branch.erp_id,
    ecommerce_id: branch.ecommerce_id,
  };
}

export function parseFormData(branch = {}) {
  return {
    address: branch.address,
    complement: branch.complement,
    cpf_cnpj: branch.cpf_cnpj,
    ddd1: branch.ddd1,
    ddd2: branch.ddd2,
    email: branch.email,
    first_name: branch.first_name,
    last_name: branch.last_name,
    neighborhood: branch.neighborhood,
    number: branch.number,
    telephone1: branch.telephone1,
    telephone2: branch.telephone2,
    zip_code: branch.zip_code,
    state_inscription: branch.state_inscription,
    erp_id: branch.erp_id,
    ecommerce_id: branch.ecommerce_id,
  };
}
