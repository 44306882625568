import { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import Tooltip from '@material-ui/core/Tooltip';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  FiMenu,
  FiChevronLeft,
  FiFileText,
  FiGitMerge,
  FiMap,
  FiSliders,
  FiCornerDownLeft,
  FiUser,
  FiArrowLeft,
} from 'react-icons/fi';

import { useAuth } from '~/providers/auth';

import LogoEfizi from '~/assets/images/logo.png';

import {
  Container,
  HeaderContent,
  LogoContent,
  MenuButton,
  DrawerContent,
  Main,
  RouteContent,
  RouteTitle,
  Logo,
  Content,
  ListItemIconContent,
  ListItemTitle,
} from './styles';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function Navigation({ children }) {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();

  const { signOut } = useAuth();

  const [open, setOpen] = useState(false);

  const locationPage = useMemo(() => {
    const locate = location?.pathname.split('/');

    const pagesDict = {
      profile: 'Perfil',
      users: 'Usuários',
      'product-integrations': 'Integração de Produtos',
      branches: 'Filiais',
      parameters: 'Parâmetros',
    };

    return {
      title: pagesDict[locate[1]] || 'Pedidos',
      subTitle: locate.length >= 3 ? pagesDict[locate[2]] : null,
    };
  }, [location]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Container className={classes.root}>
      <CssBaseline />
      <HeaderContent
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <LogoContent>
          <MenuButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, open && classes.hide)}
          >
            <FiMenu />
          </MenuButton>
          <Link to="/">
            <Logo src={LogoEfizi} style={{ width: '80px' }} />
          </Link>
        </LogoContent>
      </HeaderContent>
      <DrawerContent
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            <FiChevronLeft style={{ color: '#FB6602' }} />
          </IconButton>
        </div>
        <Divider />
        <List>
          <Tooltip title="Pedidos" arrow placement="right-start">
            <ListItem button onClick={() => history.push('/')}>
              <ListItemIconContent>
                <FiFileText size={28} />
              </ListItemIconContent>
              <ListItemTitle primary="Pedidos" />
            </ListItem>
          </Tooltip>

          <Tooltip title="Integração de produtos" arrow placement="right-start">
            <ListItem
              button
              onClick={() => history.push('/product-integrations')}
            >
              <ListItemIconContent>
                <FiGitMerge size={28} />
              </ListItemIconContent>
              <ListItemTitle primary="Integrar Produtos" />
            </ListItem>
          </Tooltip>

          <Tooltip title="Usuários" arrow placement="right-start">
            <ListItem button onClick={() => history.push('/users')}>
              <ListItemIconContent>
                <FiUser size={28} />
              </ListItemIconContent>
              <ListItemTitle primary="Usuários" />
            </ListItem>
          </Tooltip>
        </List>
        <Divider />
        <List>
          <Tooltip title="Unidades Filiais" arrow placement="right-start">
            <ListItem button onClick={() => history.push('/branches')}>
              <ListItemIconContent>
                <FiMap size={28} />
              </ListItemIconContent>
              <ListItemTitle primary="Unidades" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Parâmetros" arrow placement="right-start">
            <ListItem button onClick={() => history.push('/parameters')}>
              <ListItemIconContent>
                <FiSliders size={28} />
              </ListItemIconContent>
              <ListItemTitle primary="Parâmetros" />
            </ListItem>
          </Tooltip>
        </List>
        <Divider style={{ marginTop: 'auto' }} />
        <List>
          <ListItem button onClick={signOut}>
            <ListItemIconContent>
              <FiCornerDownLeft size={28} />
            </ListItemIconContent>
            <ListItemTitle primary="Sair" />
          </ListItem>
        </List>
      </DrawerContent>
      <Main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <RouteContent>
          {location.pathname !== '/' && (
            <IconButton
              style={{ position: 'absolute' }}
              onClick={() => history.goBack()}
            >
              <FiArrowLeft color="#fff" />
            </IconButton>
          )}
          <RouteTitle>{locationPage.title}</RouteTitle>
        </RouteContent>

        <Content>{children}</Content>
      </Main>
    </Container>
  );
}

Navigation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
