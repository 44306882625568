import * as Yup from 'yup';

import { parseDataToApi } from '~/utils/user';
import validateCpfCnpj from '~/utils/validateCpfCnpj';

export async function validate(data) {
  try {
    const schema = Yup.object().shape({
      type: Yup.string().required('Este campo é obrigatório'),
      cpf_cnpj: Yup.string()
        .required('Este campo é obrigatório')
        .when('type', {
          is: 'F',
          then: Yup.string()
            .length(11, 'Deve conter 11 caracteres')
            .test('validateCpfCnpj', 'CPF inválido', validateCpfCnpj),
          otherwise: Yup.string()
            .length(14, 'Deve conter 14 caracteres')
            .test('validateCpfCnpj', 'CNPJ inválido', validateCpfCnpj),
        }),
      email: Yup.string()
        .required('Este campo é obrigatório')
        .email('Formato de e-mail inválido'),
      name: Yup.string().required('Este campo é obrigatório'),
      login: Yup.string().required('Este campo é obrigatório'),
    });

    const parsedData = parseDataToApi(data);

    await schema.validate(parsedData, { abortEarly: false });

    return parsedData;
  } catch (error) {
    const errorMessages = {};

    if (error instanceof Yup.ValidationError) {
      error.inner.forEach(err => {
        if (!errorMessages[err.path]) errorMessages[err.path] = err.message;
      });
    }

    return { errorMessages };
  }
}
